import { render, staticRenderFns } from "./Scrollbar.vue?vue&type=template&id=60d808f8&scoped=true&"
import script from "./Scrollbar.vue?vue&type=script&lang=js&"
export * from "./Scrollbar.vue?vue&type=script&lang=js&"
import style0 from "./Scrollbar.vue?vue&type=style&index=0&id=60d808f8&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "60d808f8",
  null
  
)

export default component.exports